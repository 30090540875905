import React from "react";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import { useEffect } from 'react';
import Footer2 from "../Footer2/Footer2";
import Navbar2 from "../navbar/Navbar2";

function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isBigScreen = useMediaQuery({
    query: "(min-width: 900px)",
  });
  const videoplay = () => {
    const video = document.getElementById('background-video');
    video.play();
  };
  return (
    <>
              <Navbar2/>
      <div className="backgroundImage">
          <img className="w-[100%] h-[100%] " src="commonbg2.webp" alt="" />
      </div>
      {isBigScreen && (
        <div id="body">
          <div className="2022">
            <h3 className="pt-32" id="H_2022">
              <p className="text-[#d9d9d9] text-2xl">2022</p>
            </h3>
            <div className="container_2022 bg-transparent">
              <div className="galleryDiv bg-transparent opacity-0.5">
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
              </div>
            </div>
          </div>
          <div className="2021">
            <h3 className="pt-6" id="H_2021">
              <p className="text-[#d9d9d9] text-2xl">2021</p>
            </h3>
            <div className="container_2021">
              <div className="galleryDiv">
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
              </div>
            </div>
          </div>
          <div className="2020">
            <h3 className="pt-6" id="H_2020">
              <p className="text-[#d9d9d9] text-2xl">2018</p>
            </h3>
            <div className="container_2020">
              <div className="galleryDiv">
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
              </div>
            </div>
          </div>
          {/* <div className="videos">
            <h3 className="pt-6" id="H_2020">
              <p className="text-[#d9d9d9] text-2xl">Videos</p>
            </h3>
            <div className="container_2020">
              <div className="gallery">
                <div className="vid-box3">
                  <video src={bgImage} onLoad={videoplay}  id='background-video' autoPlay loop muted />
                </div>
                <div className="vid-box3">
                  <video src={bgImage} onLoad={videoplay}  id='background-video' autoPlay loop muted />
                </div>
                <div className="vid-box3">
                  <video src={bgImage} onLoad={videoplay}  id='background-video' autoPlay loop muted />
                </div>
              </div>
            </div>
          </div> */}<Footer2/>
        </div>
      )}
      {!isBigScreen && (
        <div id="body">
          <div className="2022">
            <h3 className="pt-20" id="H_2022">
              <p className="text-[#d9d9d9] text-2xl">2022</p>
            </h3>
            <div className="m_container_2022">
              <div className="m_gallery">
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
                <div className="img-box1"></div>
              </div>
            </div>
          </div>
          <div className="2021">
            <h3 className="pt-6" id="H_2021">
              <p className="text-[#d9d9d9] text-2xl">2021</p>
            </h3>
            <div className="m_container_2021">
              <div className="m_gallery">
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
                <div className="img-box2"></div>
              </div>
            </div>
          </div>
          <div className="2020">
            <h3 className="pt-6" id="H_2020">
              <p className="text-[#d9d9d9] text-2xl">2020</p>
            </h3>
            <div className="m_container_2020">
              <div className="m_gallery">
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
                <div className="img-box3"></div>
              </div>
            </div>
          </div>
          {/* <div className="videos">
            <h3 className="pt-6" id="H_2020">
              <p className="text-[#d9d9d9] text-2xl">Videos</p>
            </h3>
            <div className="m_container_2020">
              <div className="m_v_gallery">
                <div className="vid-box3">
                  <video src={bgImage} onLoad={videoplay}  id='background-video' autoPlay loop muted />
                </div>
                <div className="vid-box3">
                  <video src={bgImage} onLoad={videoplay}  id='background-video' autoPlay loop muted />
                </div>
                <div className="vid-box3">
                  <video src={bgImage} onLoad={videoplay}  id='background-video' autoPlay loop muted />
                </div>
              </div>
            </div>
          </div> */}
          
        </div>
        
      )}
    </>
  );
}

export default Gallery;
