import React, { useRef, useEffect } from 'react';
import './Other2.css';
import gallery from './gallery.png';
import competi from './competitions.jpg';
import esports from './esports.jpg';
import ca from './ca.jpg';
import unosq from './unosq.jpg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Other2() {
  const tl = useRef(null);
  const elementsRef = useRef([]);

  useEffect(() => {
    // GSAP animation for stacked to spread
    gsap.fromTo(
      elementsRef.current,
      {
        xPercent: -50,
        yPercent: -50,
        opacity: 0,
        scale: 0
      },
      {
        xPercent: 0,
        yPercent: 0,
        opacity: 1,
        scale: 1,
        duration: 1,
        stagger: 0.1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: '.other-container',
          start: 'top 60%',
          toggleActions: 'play none none none',
          once: true,
        }
      }
    );
  }, []);

  return (
    <div className='other-container'>
      <div className="text-white w-screen flex gap-2 lg:gap-24  mt-5 justify-center items-center">
        {/* <img className='horse1' src="BTgKKB9yc (1).png" alt="" /> */}
        <h1 className='mt-4 text-6xl font-extrabold otherHeading'>WEBSITES</h1>
        {/* <img className='horse1' src="BTgKKB9yc.png" alt="" /> */}
      </div>
      <div className='other-upper'>
        <div
          className='other-gallery other-element'
          ref={(el) => elementsRef.current[0] = el}
        >
          <a href='/gallery'>
            <img src={gallery} alt="" className='other-gallery-img other-img' />
            <p className='other-gallery-title other-title'>GALLERY</p>
          </a>
        </div>
        <div className='other-competi other-element' ref={(el) => elementsRef.current[1] = el}>
          <a href="https://events.udghosh.org.in/">
            <img src={competi} alt="" className='other-img' />
            <p className='other-title'>COMPETITIONS</p>
          </a>
        </div>
        <div className='other-social other-element' ref={(el) => elementsRef.current[2] = el}>
          <a href="/social">
            <img src="https://live.staticflickr.com/65535/53229943776_673197fc2d_h.jpg" alt="" className='other-img' />
            <p className='other-title'>SOCIAL INITIATIVES</p>
          </a>
        </div>
        <div className='other-esports other-element' ref={(el) => elementsRef.current[3] = el}>
          <a href="https://esports.udghosh.org.in/">
            <img src={esports} alt="" className='other-img' />
            <p className='other-title'>E-SPORTS</p>
          </a>
        </div>
        <div className='other-ca other-element' ref={(el) => elementsRef.current[4] = el}>
          <a href="https://ca.udghosh.org.in/">
            <img src={ca} alt="" className='other-img' />
            <p className='other-title'>CA</p>
          </a>
        </div>
        <div className='other-unosq other-element' ref={(el) => elementsRef.current[5] = el}>
          <a href="https://unosq.udghosh.org.in/">
            <img src={unosq} alt="" className='other-img' />
            <p className='other-title'>UNOSQ</p>
          </a>
        </div>
      </div>
      {/* <div className='other-lower'> */}
      {/* <div className='other-esports other-element' ref={(el) => elementsRef.current[3] = el}>
          <a href="https://esports.udghosh.org.in/">
            <img src={esports} alt="" className='other-img' />
            <p className='other-title'>E-SPORTS</p>
          </a>
        </div>
        <div className='other-ca other-element' ref={(el) => elementsRef.current[4] = el}>
          <a href="https://ca.udghosh.org.in/">
            <img src={ca} alt="" className='other-img' />
            <p className='other-title'>CA</p>
          </a>
        </div>
        <div className='other-unosq other-element' ref={(el) => elementsRef.current[5] = el}>
          <a href="https://unosq.udghosh.org.in/">
            <img src={unosq} alt="" className='other-img' />
            <p className='other-title'>UNOSQ</p>
          </a>
        </div> */}
      {/* </div> */}
    </div>
  );
}
