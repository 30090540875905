import React, { useState, useEffect } from 'react';
import './Teams.css';
import { Link } from 'react-scroll';
import teamsData from './teams.json';
import 'remixicon/fonts/remixicon.css';
import Navbar2 from '../../components/navbar/Navbar2';

export default function Teams() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if (!isLargeScreen) return;

    const boxes = document.querySelectorAll('.box');

    const handleMouseMove = (e, box) => {
      const boxRect = box.getBoundingClientRect();
      const x = e.clientX - boxRect.left;
      const y = e.clientY - boxRect.top;

      const centerX = boxRect.width / 2;
      const centerY = boxRect.height / 2;

      // Smaller rotation values for smoother effect
      const rotateX = (centerY - y) / centerY * 10;
      const rotateY = (x - centerX) / centerX * 10;

      box.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };
    const throttle = (func, limit) => {
      let lastFunc;
      let lastRan;
      return function (...args) {
        if (!lastRan) {
          func(...args);
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = setTimeout(() => {
            if ((Date.now() - lastRan) >= limit) {
              func(...args);
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      };
    };

    // Function to reset the rotation when the mouse leaves the box
    const handleMouseLeave = (box) => {
      box.style.transform = 'rotateX(0deg) rotateY(0deg)';
    };

    // Add event listeners to all the boxes
    boxes.forEach((box) => {
      box.addEventListener('mousemove', throttle((e) => handleMouseMove(e, box), 16));  // Throttling at 60fps
      box.addEventListener('mouseleave', () => handleMouseLeave(box));
    });

    // Cleanup event listeners on component unmount
    return () => {
      boxes.forEach((box) => {
        box.removeEventListener('mousemove', handleMouseMove);
        box.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return (
    <>
      <Navbar2 />
      <div className='teams-container pb-32'>
        <div className="teams-overlay"></div>
        <div className="fixed top-0 h-[80px] w-screen bg-[rgba(54,37,17,0.2)]"></div>
        <div className='teams-list'>
          <div className='teams-filled-half'></div>
          <div className='teams-mid-arc'></div>
          <div className='teams-out-arc'></div>
          <div className='teams-verti'>
            {teamsData.teams.map((team, index) => (
              <div key={index} className={`teams-${team.id.split('-')[2]} teams-headings`}>
                <Link style={{ cursor: "pointer" }} activeClass="active" to={team.id} smooth={true} duration={500} spy={true} offset={-105}>
                  {team.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className='teams-content'>
          <div className='teams-header'>TEAMS</div>
          {teamsData.teams.map((team, index) => (
            <div key={index} className='teams-about' id={team.id}>
              <div className='teams-title'>{team.title}</div>
              <div className='teams-all-photos'>
                {/* {team.members.map((member, memberIndex) => ( */}
                {/* // <div key={memberIndex} className='teams-photos'>
                  //   <img src={member.photo} alt="" className='teams-head-photo' />
                  //   <p className='teams-firstname'>{member.name}</p>
                  //   <div className='teams-socials'>
                  //     <div className='teams-heads-socials'>
                        // <a href={member.linkedin} target="_blank" rel="noopener noreferrer"><i className='fab fa-linkedin icon'></i></a>
                        // <a href={tel:${member.phone}} target="_blank" rel="noopener noreferrer"><i className='fa fa-phone'></i></a>
                        // <a href={mailto:${member.email}} target="_blank" rel="noopener noreferrer"><i className='fa fa-envelope'></i></a>
                  //     </div>
                  //     <div className='teams-socials-line'></div>
                  //   </div>
                  // </div> */}


                {team.members.map((member, memberIndex) => (
                  <div key={memberIndex} className='teams-photos'>
                    <div className="box flex justify-center items-center">
                      <div className="imagecontent flex flex-col gap-6 items-center">
                        <div className='imageMember'>
                          <img src={member.photo} alt="" />
                        </div>
                        <div className="description">
                          <p className='text-[18px] text-gray-200 font-medium'>{member.name}</p>
                        </div>
                      </div>
                      <div className="backBox absolute bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40">
                        <div className="icons absolute bottom-0 w-[100%] px-12 pb-2 flex justify-between items-center">
                          <a href={member.linkedin}><i className='fab fa-linkedin icon'></i></a>
                          <a href={`tel:${member.phone}`}><i className='fa fa-phone'></i></a>
                          <a href={`mailto:${member.email}`}><i className='fa fa-envelope'></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
