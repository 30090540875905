import React, { useLayoutEffect } from "react";
import "./Aftermovies2.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const EventCard = ({ image, name, date, link }) => {
  return (
    <a className="anchorTag" href={`${link}`}>
      <div
      className={`relative cardAfterMovies bg-cover bg-center`}
      style={{ clipPath: 'polygon(15% 5%, 100% 0%, 90% 95%, 5% 100%)' }}
    >
      <img className="object-cover object-center w-[100%]" src={`${image}`} alt="" />
      <div className="absolute inset-0 bg-black bg-opacity-10"></div>
      {/* <div className="absolute rotate-[-6deg] bottom-5 left-0 text-center w-full text-white z-10">
        <h2 className="text-2xl font-bold">{name}</h2>
        <p className="text-lg mt-2">{date}</p>
      </div> */}
      <div className="absolute top-5 right-5 z-10 text-white text-3xl transform rotate-45">
        &#8599;
      </div>
      <div className="absolute youtubeIcon">
        <img src="pngwing.com (11).png" alt="" />
      </div>
    </div>
    </a>
  );
};

export default function AfterMovies2() {

useGSAP(() => {
    const cards = document.querySelectorAll(".cardAfterMovies");
  
    // Initial scale from 0 to 1.5 with bounce back to 1
    gsap.fromTo(cards, 
      { scale: 0, opacity: 0 },  // Start from scale 0
      { 
        scale: 1.1,   // Scale to 1.5
        opacity: 1,   // Fade in opacity
        duration: 2.5, 
        stagger: 0.2,
        ease: "elastic.out(1,0.4)",  // Bouncy easing on scaling back to 1
        scrollTrigger: {
          trigger: ".afterMovies",
          start: "top 50%",
          end: "bottom center",
          toggleActions: "play none none none",
          once: true,  // Play only once when triggered
        },
      }
    );
  }, []);
  
  const events = [
    {
      image: '/Aftermovies 2023.jpg',
      link:"https://youtu.be/XZevkj2qpf0?si=MQeHYb8rawxkFa2D"
    },
    {
      image: '/WhatsApp Image 2024-09-25 at 22.07.53_77040e06.jpg',
      link:"https://www.youtube.com/watch?v=GP2WpyK5Fng"
    },
    {
      image: '/Untitled design (1).png',
      link:"https://www.youtube.com/watch?v=Rm8lM1WskBc"
    },
  ];

  return (
    <>
    <div className="text-white w-screen flex gap-2 lg:gap-24 mt-24 justify-center items-center">
        {/* <img className='horse2' src="BTgKKB9yc (1).png" alt="" /> */}
        <h1 className='mt-4 mb-6 text-6xl font-extrabold afterMoviesHeading'>AFTERMOVIES</h1>
        {/* <img className='horse2' src="BTgKKB9yc.png" alt="" /> */}
      </div>
    <div className="afterMovies h-auto xl:h-screen flex pb-16 items-center gap-6 lg:gap-10 xl:flex-row justify-center xl:space-x-6 xl:p-6">
      {events.map((event, index) => (
        <EventCard key={index} name={event.name} date={event.date} image={event.image} link={event.link}/>
      ))}
    </div>
    </>
  );
}
// bg-gradient-to-b from-black to-gray-900