// import React, { useEffect } from 'react';
// import './Header4.css';
// import Navbar2 from '../navbar/Navbar2';
// import gsap from 'gsap';
// import { useGSAP } from '@gsap/react';
// const Header4 = () => {

//   useEffect(() => {
//     window.scrollTo(0,0)
//   }, []);

//   useEffect(() => {
//     const parallaxElements = document.querySelectorAll('.parallax');

//     const handleMouseMove = (e) => {
//       const xValue = e.clientX - window.innerWidth / 2;
//       const yValue = e.clientY - window.innerHeight / 2;

//       parallaxElements.forEach((el) => {
//         const speedX = el.dataset.speedx;
//         const speedY = el.dataset.speedy;

//         const leftValue = parseFloat(getComputedStyle(el).left);
//         const isLeft = leftValue < window.innerWidth / 2 ? 1 : -1;
//         const zValue = (e.clientX - leftValue) * isLeft;

//         el.style.transform = `translateX(calc(-50% + ${
//           -xValue * speedX
//         }px)) translateY(calc(-50% + ${
//           yValue * speedY
//         }px)) perspective(2300px) translateZ(${0.1 * zValue}px)`;
//       });
//     };

//     window.addEventListener('mousemove', handleMouseMove);

//     return () => {
//       window.removeEventListener('mousemove', handleMouseMove);
//     };
//   }, []);

//   useGSAP(()=>{
//     gsap.to(".fog-5-1",{
//       x:-300,
//       duration:1,
//       scrollTrigger:{
//           trigger:"main", // whenever pin is to applied to child then trigger its parent only
//           scroll:"body",
//           start:"top 0%",
//           end:"top -150%",
//           scrub:2,
//           pin:true,
//       }
//   }) 
//   })
//   return (
//     <>
//     <Navbar2/>
//     <main>
//       {/* <div className="vignette"></div> */}
//       <img src="WhatsApp Image 2024-09-27 at 00.57.00_ec030f42.jpg" data-speedx="0.07" data-speedy="0.08" alt="" className="parallax bg-img" />
// {/* <img src="img/fog_7.png" alt="" data-speedx="0.24" data-speedy="0.24" className="parallax fog-7" /> */}
// {/* <img src="Picsart_24-09-27_01-17-59-679.png" alt="" data-speedx="0.085" data-speedy="0.085" className="parallax mountain-10" /> */}
// {/* <img src="img/fog_6.png" alt="" data-speedx="0.25" data-speedy="0.28" className="parallax fog-6" /> */}
// {/* <img src="img/mountain_9.png" alt="" data-speedx="0.125" data-speedy="0.155" className="parallax mountain-9" /> */}
// {/* <img src="img/mountain_8.png" alt="" data-speedx="0.1" data-speedy="0.11" className="parallax mountain-8" /> */}
// {/* <img src="img/mountain_4.png" alt="" data-speedx="0.05" data-speedy="0.03" className="parallax mountain-4-2" /> */}
// {/* <img src="img/mountain_6.png" alt="" data-speedx="0.065" data-speedy="0.05" className="parallax mountain-6" /> */}
// {/* <img src="img/fog_4.png" alt="" data-speedx="0.135" data-speedy="0.04" className="parallax fog-4" /> */}
// {/* <img src="img/mountain_5.png" alt="" data-speedx="0.08" data-speedy="0.024" className="parallax mountain-5" /> */}
// {/* <img src="img/fog_3.png" alt="" data-speedx="0.11" data-speedy="0.018" className="parallax fog-3" /> */}
// {/* <img src="img/mountain_4.png" alt="" data-speedx="0.08" data-speedy="0.05" className="parallax mountain-4" /> */}
// {/* <img src="img/mountain_3.png" alt="" data-speedx="0.04" data-speedy="0.018" className="parallax mountain-3" /> */}
// {/* <img src="img/fog_2.png" alt="" data-speedx="0.15" data-speedy="0.0115" className="parallax fog-2" /> */}
// {/* <img src="img/mountain_2.png" alt="" data-speedx="0.023" data-speedy="0.013" className="parallax mountain-2" /> */}
// {/* <img src="img/mountain_1.png" alt="" data-speedx="0.027" data-speedy="0.048" className="parallax mountain-1" /> */}
//       <img src="img/sun_rays.png" alt="" className="sun-rays" />
//       {/* <img src="img/black_shadow.png" alt="" className="black-shadow" /> */}
//       {/* <img src="img/fog_1.png" alt="" data-speedx="0.12" data-speedy="0.12" className="parallax fog-1" /> */}
//     </main>
//     </>
//   );
// };

// export default Header4;


import React, { useEffect, useState } from 'react';
import './Header4.css';
import Navbar2 from '../navbar/Navbar2';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

const Header4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);
    checkScreenSize(); // initial check

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const parallaxElements = document.querySelectorAll('.parallax');

    const handleMouseMove = (e) => {
      const xValue = e.clientX - window.innerWidth / 2;
      const yValue = e.clientY - window.innerHeight / 2;

      parallaxElements.forEach((el) => {
        const speedX = el.dataset.speedx;
        const speedY = el.dataset.speedy;

        const leftValue = parseFloat(getComputedStyle(el).left);
        const isLeft = leftValue < window.innerWidth / 2 ? 1 : -1;
        const zValue = (e.clientX - leftValue) * isLeft;

        el.style.transform = `translateX(calc(-50% + ${-xValue * speedX}px)) translateY(calc(-50% + ${yValue * speedY}px)) perspective(2300px) translateZ(${0.007 * zValue}px)`;
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useGSAP(() => {
    const tl = gsap.timeline();

  });

  return (
    <>
      <Navbar2 />
      <main>

        {isMobile ? (
          <>
            <div className="blackLayer"></div>
            <div className="vignette"></div>
            <img src="web final/image.jpeg" data-speedx="0" data-speedy="0" alt="" className="parallax bg-img" />
            <img src="typeface2.png" alt="" data-speedx="0" data-speedy="0" className="parallax typeface" style={{width:"100vw"}} />

          </>

        ) : (
          <> 
            <div className="blackLayer"></div>
            <div className="vignette"></div>
            <img src="web final/web/bg2.jpg" data-speedx="0.07" data-speedy="0.05" alt="" className="parallax bg-img" />
            <img src="web final/web/player 1.png" alt="" data-speedx="0.0525" data-speedy="0.0355" className="parallax player-1" />
            <img src="web final/web/player 2.png" alt="" data-speedx="0.0525" data-speedy="0.0355" className="parallax player-2" />
            <img src="web final/web/player 3.png" alt="" data-speedx="0.0525" data-speedy="0.0355" className="parallax player-3" />
            <img src="web final/web/player 4.png" alt="" data-speedx="0.0525" data-speedy="0.0355" className="parallax player-4" />
            <img src="web final/web/player 5.png" alt="" data-speedx="0.06" data-speedy="0.0355" className="parallax player-6" />
            <img src="typeface2.png" alt="" data-speedx="0" data-speedy="0" className="parallax typeface" />
          </>
        )}
      </main>
    </>
  );
};

export default Header4;
