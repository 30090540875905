import React from 'react';
import './Sponsors.css';
import SponFrame from './SponFrame';
import sponsorsJson from '../../json/Sponsors.json';
import sponsorheader from './sponsorheader-compressed.png';
import Footer2 from "../Footer2/Footer2.jsx";
import Navbar2 from '../navbar/Navbar2.jsx';

export default function Sponsors() {
    return (
        <>
                  <Navbar2/>
            <div className='sponsors-container'>
                <div className='sponsors-back' alt='sponsorsback' >
                </div>
                <div className="sponsors-leftpart fixed h-full">
                    <div className="sponsors-header">
                        <img className='sponsors-header-frame' src={sponsorheader} alt='sponsorheader' />
                        <h1 className="sponsors-heading">Sponsors</h1>
                        <p className="sponsors-para">UDGHOSH success is unmatched and this is due to renowned partners we have who have always provided unequivocal support . The mutual work between udhgosh and it's partners have always lived up to the expectations of the audience and attracted a new audience.</p>
                    </div>
                </div>
                <div className="sponsors-rightpart right-0">
                    {Object.keys(sponsorsJson).map((key, i) => {
                        if (i % 2 == 0) {
                            return (
                                <div className='sponsor-trail-container'>
                                    <div className="sponsors-trail-1 sponsors-trail"></div>
                                    <div className="sponsors-trail-2 sponsors-trail">
                                        <h2 className="sponsor-heading">{key}</h2>
                                        {sponsorsJson[key].links.map((sponsor, i) => {
                                            console.log(sponsor);
                                            return (
                                                <SponFrame src={sponsor.img} href={sponsor.redirect.toString()} alt={sponsor.name} desc="" />
                                            );
                                        })}
                                    </div>

                                </div>
                            );
                        }
                        else {
                            return (
                                <div className='sponsor-trail-container'>
                                    <div className="sponsors-trail-1 sponsors-trail">
                                        <h2 className="sponsor-heading">{key}</h2>
                                        {sponsorsJson[key].links.map((sponsor, i) => {
                                            console.log(sponsor);
                                            return (
                                                <SponFrame src={sponsor.img} href={sponsor.redirect.toString()} alt={sponsor.name} desc="" />
                                            );
                                        })}
                                    </div>
                                    <div className="sponsors-trail-2 sponsors-trail"></div>

                                </div>
                                
                            );
                        }
                    })}
                </div>
            </div>
            {/* <Footer2/> */}
            
            

        </>
    );
}