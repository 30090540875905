import React from 'react';
import { FaInstagram, FaLinkedin, FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa';
import "./Footer2.css";
import { NavLink } from "react-router-dom";

const Footer2 = () => {
  return (
    <div className="containerFooter flex flex-row  h-[30%] border-2" style={{ borderColor: "rgb(47, 44, 44)" }}>
      <div className='textbox flex flex-row'>
        <div className='imgboxx m-3 flex justify-center content-center items-center' >
          <img className='Logo' src='./logo.png'/>
        </div>
        <div className=' flex flex-col justify-center content-center gap-2' style={{ color: "rgb(233, 233, 209)" }}>
          <div className='text-right text-xl font-medium'>
            Udghosh'24
          </div>
          <p className='text-right text-sm'>
            Udghosh aims to maintain high commitment levels and inspire talent with sports icons. Keeping our goal and pledge, we invite you to Udghosh 2024.
          </p>
        </div>
      </div>

      <div className="buttons flex flex-row justify-center border-x-2" style={{ borderColor: "rgb(47, 44, 44)" }}>
        <div className='Navlinks'>
          <a href="https://www.instagram.com/udghosh_iitk/?hl=en">
            <FaInstagram className="icon-size" />
          </a>
        </div>
        <div className='Navlinks'>
          <a href="https://www.youtube.com/@Udghoshiitk">
            <FaYoutube className="icon-size" />
          </a>
        </div>
        <div className='Navlinks'>
          <a href="https://twitter.com/udghoshiitk?lang=en">
            <FaTwitter className="icon-size" />
          </a>
        </div>
        <div className='Navlinks'>
          <a href="https://www.linkedin.com/company/udghosh-iit-kanpur/about/">
            <FaLinkedin className="icon-size" />
          </a>
        </div>
        <div className='Navlinks'>
          <a href="https://www.facebook.com/udghosh.iitk/">
            <FaFacebook className="icon-size" />
          </a>
        </div>
      </div>
      <div className='links flex flex-col justify-center content-center  '
        style={{ borderColor: "rgb(47, 44, 44)" }}>
        <div className='flex flex-row justify-between' >
          <div className="nav-item">
            <NavLink to="/" className="nav-link  px-4" >
              Home
            </NavLink>
          </div>
          <div className="nav-item">
            <NavLink to="https://events.udghosh.org.in" className="nav-link  px-4">
              Competitions
            </NavLink>
          </div>

        </div>

        <div className='flex flex-row justify-between' >
          <div className="nav-item">
            <NavLink to="/teams" className="nav-link  px-4">
              Our Team
            </NavLink>
          </div>
          <div className="nav-item">
            <NavLink to="/sponsors" className="nav-link  px-4">
              Sponsors
            </NavLink>
          </div>
        </div>

        <div className='flex flex-row justify-between'>
          <div className="nav-item">
            <NavLink to="/gallery" className="nav-link  px-4">
              Gallery
            </NavLink>
          </div>
          <div className="nav-item">
            <NavLink to="/social" className="nav-link  px-4">
              Social Initiatives
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer2;


