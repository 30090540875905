import React from "react";
import "./Homepage.css";
import Header4 from "../Header4/Header4.jsx";
import Showcase2 from "../Showcase/Showcase2.jsx";
import Other2 from "../other_websites/Other2.jsx";
import AfterMovies2 from "../Aftermovies/Aftermovies2.jsx";
import AfterMovies3 from "../Aftermovies/Aftermovies3.jsx";
import Newabout from "../Newabout/Newabout";
import Footer2 from "../Footer2/Footer2.jsx";
import Navbar2 from "../navbar/Navbar2.jsx";
import NotificationBar from "../NotificationBar/index.js";


export default function Homepage() {
  
  return (
    <>
      <Navbar2/>
      <NotificationBar
          message="Teams representing IITK will not be eligible for prize money, regardless of their performance or victories in any of the sports competitions."
          duration={5000}
        />
      <div className="backgroundImage">
          <img className="w-[100%] h-[100%] " src="commonbg2.webp" alt="" />
      </div>
      <Header4 />
      <Newabout/>
      <Showcase2 />
      <Other2 />
      <AfterMovies2 />
      <Footer2/>
    </>
  );
}
