import React from 'react'
import Footer2 from "../Footer2/Footer2.jsx";
function initiatives() {
  return (
    <>
    <div>initiatives</div>
    <Footer2/>
    </>
  )
}

export default initiatives