import React from 'react'
import Footer2 from "../Footer2/Footer2.jsx";
import Navbar2 from '../navbar/Navbar2.jsx';
function antique() {
  return (
    <>
    <Navbar2/>
    <div>antique</div>
    <Footer2/>
    </>
  )
}

export default antique