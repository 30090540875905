import React, { useLayoutEffect } from "react";
import "./Aftermovies3.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const EventCard = ({ image, name, date, link }) => {
    return (
        <a className="anchorTag" href={`${link}`}>
            <div className={`relative cardAfterMovies3 bg-cover bg-center`}>
                <img className="object-cover object-center w-[100%]" src={`${image}`} alt="" />
                <div className="absolute inset-0 bg-black bg-opacity-10"></div>
                <div className="absolute top-5 right-5 z-10 text-white text-3xl transform rotate-45">
                    &#8599;
                </div>
                <div className="absolute youtubeIcon">
                    <img src="pngwing.com (11).png" alt="" />
                </div>
            </div>
        </a>
    );
};

export default function AfterMovies3() {
    //   useGSAP(() => {
    //     const cards = document.querySelectorAll(".cardAfterMovies");

    //     gsap.fromTo(
    //       cards,
    //       { scale: 0, opacity: 0 }, // Start from scale 0
    //       {
    //         scale: 1.1, // Scale to 1.1
    //         opacity: 1, // Fade in opacity
    //         duration: 2.5,
    //         delay:0,
    //         ease: "elastic.out(1, 0.5)", // Bouncy easing on scaling back to 1
    //         scrollTrigger: {
    //           trigger: ".afterMovies",
    //           start: "top 50%", // Trigger when top of `.afterMovies` hits 70% of viewport
    //           end: "center 100%",
    //           toggleActions: "play none none none",
    //           once: true, // Play only once when triggered
    //         },
    //       }
    //     );
    //   }, []);
        const events = [
            {
                image: '/Aftermovies 2023.jpg',
                link: "https://youtu.be/XZevkj2qpf0?si=MQeHYb8rawxkFa2D"
            },
            {
                image: '/WhatsApp Image 2024-09-25 at 22.07.53_77040e06.jpg',
                link: "https://www.youtube.com/watch?v=GP2WpyK5Fng"
            },
            {
                image: '/Untitled design (1).png',
                link: "https://www.youtube.com/watch?v=Rm8lM1WskBc"
            },
        ];

        return (
            <>
                <div className="text-white w-screen flex gap-2 mt-24 mb-6 xl:mb-2 justify-center items-center">
                    <h1 className="text-6xl font-extrabold afterMoviesHeading">AFTERMOVIES</h1>
                </div>
                <div className="afterMovies h-auto xl:h-[80vh] flex pb-16 items-center gap-6 lg:gap-24 xl:flex-row justify-center xl:space-x-6 xl:p-6">
                    {events.map((event, index) => (
                        <EventCard key={index} name={event.name} date={event.date} image={event.image} link={event.link} />
                    ))}
                </div>
            </>
        );
    }
