import React from 'react'
import Footer2 from "../Footer2/Footer2.jsx";
function impacts() {
  return (
    <>
    <div>impacts</div>
    <Footer2/>
    </>
  )
}

export default impacts