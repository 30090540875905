import React from 'react'
import Footer2 from "../Footer2/Footer2.jsx";
function vision() {
  return (
    <>
    <div>vision</div>
    <Footer2/>
    </>
  )
}

export default vision