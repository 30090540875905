import React from 'react';

export default function SponFrame({ src, alt, desc, href }) {
    return (
        <>
            <a className='sponsor-img-frame' target="_blank" rel="noreferrer" href={href} >
                <img  src={src} alt={alt} desc={desc} />
            </a>
        </>
    )
}