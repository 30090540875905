import React, { useState, useEffect } from 'react';
import "./Showcase2.css";
import { gsap } from "gsap";

const Showcase2 = () => {
  const images = [
    {
      src: "https://live.staticflickr.com/65535/54021765727_31d26a1946_h.jpg",
      alt: "Participants at the Pronite event",
      detail: "Pronite - A vibrant cultural night filled with performances, music, and celebration."
    },
    {
      src: "https://live.staticflickr.com/65535/54022645366_285aa35812_h.jpg",
      alt: "Action shot of a table tennis match",
      detail: "Table Tennis - Fast-paced action showcasing agility and precision among competitors."
    },
    {
      src: "https://live.staticflickr.com/65535/54023095970_7c6daf3c00_b.jpg",
      alt: "Men's basketball game in progress",
      detail: "Basketball Men - A thrilling match highlighting teamwork and athleticism on the court."
    },
    {
      src: "https://live.staticflickr.com/65535/54022646216_69248d7373_b.jpg",
      alt: "Women's basketball team celebrating a victory",
      detail: "Basketball Women - Celebrating skills and camaraderie during an exciting women's match."
    },
    {
      src: "https://live.staticflickr.com/65535/54023095795_157a80d716_b.jpg",
      alt: "Participants in the 'We Fight Cancer' session",
      detail: "'We Fight Cancer' Session - A heartfelt gathering promoting awareness and support for cancer patients."
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [angle, setAngle] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);

  useEffect(() => {
    if (!isHovered && !isHovered1) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        setAngle((prevAngle) => (prevAngle + 180));
      }, 3500);

      return () => clearInterval(interval);
    }
  }, [images.length, isHovered]);

  useEffect(() => {
    if (isHovered) {
      gsap.fromTo(
        "#text-box",
        { opacity: 0, y: -60 },
        { opacity: 1, y: 0, duration: 0.5 }
      );
    }
  }, [isHovered]);

  const getPositionAndScale = (index) => {
    const totalImages = images.length;
    const distance = (index - activeIndex + totalImages) % totalImages;

    const translateX = Math.min(distance, totalImages - distance) * -400;
    const scale = 1 - 0.15 * Math.min(distance, totalImages - distance);

    if (distance === 0) {
      return {
        transform: `translateX(0) scale(${isHovered ? 1.2 : 1.25})`,
        zIndex: 20,
        opacity: 1,
      };
    } else if (distance < totalImages / 2) {
      return {
        transform: `translateX(${-translateX}px) scale(${scale})`,
        zIndex: 10 - distance,
        opacity: 1 - 0.2 * distance,
      };
    } else {
      return {
        transform: `translateX(${translateX}px) scale(${scale})`,
        zIndex: 10 - (totalImages - distance),
        opacity: 1 - 0.2 * (totalImages - distance),
      };
    }
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % images.length;
      console.log("Next Index:", newIndex); // Debugging log
      return newIndex;
    });
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + images.length) % images.length;
      console.log("Previous Index:", newIndex); // Debugging log
      return newIndex;
    });
  };

  return (
    <div className='gallery flex flex-col bg-center bg-cover'>
      <div className="relative flex flex-col justify-center items-center w-screen h-[70vh] md:h-[90vh] overflow-hidden">
        <div className="text-white absolute top-0 w-screen flex gap-2 lg:gap-24 mt-5 justify-center items-center">
          <h1 className='mt-4 text-6xl font-extrabold galleryHeading'>GLIMPSES</h1>
        </div>
        {images.map((image, index) => (
          <div
            key={index}
            className="boxDiv absolute transition-all duration-500 ease-in-out border-2 border-gray-700 rounded-md xl:rounded-xl"
            style={getPositionAndScale(index)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={image.src}
              alt={image.alt}
              className="showsImage relative z-[10] object-cover rounded-md xl:rounded-xl shadow-lg object-top"
            />
            {/* Text box for active card */}
            {index === activeIndex && isHovered && (
              <div id="text-box" className="text-box absolute z-[-1] bottom-[-110px] md:bottom-[-80px] left-1/2 transform -translate-x-1/2 bg-[rgba(0,0,0,0.5)] text-white p-2 border-2 border-gray-400 rounded shadow-lg">
                <p className='text-sm w-[260px] sm:w-[400px]'>{image.detail}</p>
              </div>
            )}
          </div>
        ))}
        {/* Navigation Buttons */}
        <button 
          onClick={handlePrev} 
          onMouseEnter={() => setIsHovered1(true)}
          onMouseLeave={() => setIsHovered1(false)}
          className="sliderButton absolute left-[4px] top-1/2 transform -translate-y-1/2 opacity-[0.7] bg-white p-[-1px] text-black rounded-full shadow-lg z-30"
        >
         <img className='w-7 md:w-10' src="previous (1).png" alt="" />
        </button>
        <button 
          onClick={handleNext} 
          onMouseEnter={() => setIsHovered1(true)}
          onMouseLeave={() => setIsHovered1(false)}
          className="sliderButton absolute right-[4px] top-1/2 transform -translate-y-1/2 opacity-[0.7] bg-white p-[-1px] text-black rounded-full shadow-lg z-30"
        >
          <img className='w-7 md:w-10' src="next (1).png" alt="" />
        </button>
      </div>
    </div>
  );
};

export default Showcase2;
